<template>
  <b-modal v-model="modal" size="lg" hide-footer>
    <h5 class="mb-4" v-if="Object.keys(date).length">
      Desde: {{ date.start_date }} |
      Hasta: {{ date.end_date }}
    </h5>
    <apexchart width="100%" type="donut" :options="options" :series="series"></apexchart>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      modal: false,
      date: {}
    }
  },
  computed: {
    ...mapGetters({
      options: 'reports/mapOptions',
      series: 'reports/mapSeries',
    })
  }
}
</script>

<style lang="scss" scoped></style>