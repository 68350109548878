<template>
  <Layout>
    <PageHeader :title="title" moda_title="Datos del operador" ref="pageForm" :items="items">
    </PageHeader>
    <Show ref="showJacks" :jackpots="jackpots"></Show>
    <sister-round ref="sisterRound" :round="round" :rounds="rounds"
      :roundIdentifierNumber="roundIdentifierNumber"></sister-round>
    <dragon-result ref="dragonTigerResult" :dragon-tiger="game"></dragon-result>
    <BlackJackResult ref="BlackJackResult" :game="game" :round="round" />
    <RoundStatistics ref="showRoundStatisticsComponent" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <Tooltip>
                <p>
                  En este apartado se puede observar el historial de rondas.
                </p>
                <ul>
                  <li>
                    Al darle click al 'Round ID' podrás ver las transacciones de
                    esa ronda.
                  </li>
                  <li>
                    Puedes observar:
                    <ul>
                      <li>Jackpots</li>
                      <li>Apuestas</li>
                      <li>Rondas hermanas</li>
                    </ul>
                  </li>
                </ul>
              </Tooltip>
            </div>
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t("filter.from") }}: </span>
                  <vs-input v-model="filterData.start_date" type="date"></vs-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t("filter.until") }}: </span>
                  <vs-input v-model="filterData.end_date" type="date"></vs-input>
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-12 d-flex justify-content-start" v-if="userRole !== 'OPERATOR'">
                <label class="d-inline-flex align-items-center" style="width: max-content">
                  {{ $t("filter.gameType") }}:
                </label>
                <select v-model="filterData.gameType" name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50" placeholder="Seleccione.."
                  @change="handleChange(filterData.gameType)">
                  <option disabled selected>
                    {{ $t("helpers.select") }}...
                  </option>
                  <option v-for="(item, index) in mapGamesTypes" :key="index" :value="item.value">
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <div class="col-sm-12 col-md-4" v-if="checkAdmins()">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.client") }}:
                  <select v-model="filterData.client" name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg" placeholder="Seleccione.."
                    @change="change">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option v-for="(item, index) in clients" :key="index" :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="userRole !== 'OPERATOR'">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.operator") }}:
                  <select v-model="filterData.operator" name="operator"
                    class="form-select form-select-sm m-2 border-0 shadow-lg" placeholder="Seleccione.."
                    @change="change">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option v-for="(item, index) in operators" :key="index" :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.gameType">
                <label class="d-inline-flex align-items-center w-100">
                  Juego:
                  <select v-model="filterData.roulette" name="ruleta"
                    class="form-select form-select-sm m-2 border-0 shadow-lg" placeholder="Seleccione.."
                    @change="change">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option v-for="(item, index) in games" :key="index" :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
              <vs-input placeholder="Identifier Number" border class="shadow-lg me-2"
                v-model="filterData.identifierNumber" />
              <vs-button>
                <downloadexcel :fetch="exportData" :fields="json_fields" :name="fileName">
                  {{ $t("filter.downloadExcel") }}
                </downloadexcel>
              </vs-button>
              <DateRange @handleRadio="handleRadio" />
              <vs-button type="button" @click="filtrar()">
                {{ $t("filter.filter") }}
              </vs-button>
              <vs-button type="button" success @click="cancelFilter()" :disabled="!Object.entries(filterData).length">
                {{ $t("filter.cancel") }}
              </vs-button>
              <vs-button type="button" success @click="roundStatistics"
                v-if="filterData.gameType === gamesTypes.WheelFortune" :disabled="[filterData.end_date, filterData.start_date].includes(
                  undefined
                )
                  ">
                Ver estadisticas
              </vs-button>
            </div>

            <div class="text-center" v-if="fetching">
              <b-spinner type="grow" label="Spinning"></b-spinner>
              {{ $t("helpers.loading") }}...
            </div>

            <div class="row mt-2" v-if="tableData.length">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t("filter.show") }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option v-for="(op, index) in pageOptions" :key="index" :label="op" :value="op">{{ op
                        }}</vs-option>
                    </vs-select>
                    &nbsp;{{ $t("filter.entries") }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t("filter.search") }}:
                    <vs-input v-model="filter" type="search" primary class="shadow-lg"></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
              <!-- <TotalRegister :totalRegister="totalRegister"></TotalRegister> -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0" v-if="tableData.length">
                <b-table :items="tableData" :fields="fields" responsive="sm" :filter-included-fields="filterOn"
                  :filter="filter" :sort-by="sortBy" :sort-desc="sortDesc">
                  <template #cell(start_date)="data">
                    <div class="createdAt" @mouseover="handleOver" @mouseleave="handleLeave">
                      {{
                        moment(data.item.start_date).format(
                          "DD/MM/YYYY | HH:mm:ss"
                        )
                      }}
                      <span class="badge badge-time bg-primary rounded-pill">Server time:
                        {{
                          moment
                            .utc(data.item.start_date)
                            .format("DD/MM/YYYY | HH:mm:ss")
                        }}</span>
                    </div>
                  </template>
                  <template #cell(end_date)="data">
                    <div class="createdAt" @mouseover="handleOver" @mouseleave="handleLeave">
                      {{
                        moment(data.item.end_date).format(
                          "DD/MM/YYYY | HH:mm:ss"
                        )
                      }}
                      <span class="badge badge-time bg-primary rounded-pill">Server time:
                        {{
                          moment
                            .utc(data.item.end_date)
                            .format("DD/MM/YYYY | HH:mm:ss")
                        }}</span>
                    </div>
                  </template>
                  <template #cell(code)="data">
                    <span @click="setTransactionsRound(data.item)" style="cursor: pointer"
                      class="text-primary fw-bolder">
                      {{ data.item.code }}
                    </span>
                  </template>
                  <template #cell(jackpotRound)="data">
                    <vs-button @click="lookJackpots(data.item)" success>
                      {{ $t("tableReports.jackpotsRoundBtn") }}
                    </vs-button>
                  </template>
                  <template #cell(sisterRound)="data">
                    <div class="d-flex justify-content-center">
                      <vs-button @click="lookSisterRound(data.item)" success>
                        {{ $t("helpers.look") }}
                      </vs-button>
                    </div>
                  </template>
                  <!-- TODO: Da error en filter all -->
                  <template #cell(name)="data">
                    <span>
                      {{
                        data.item.code
                          ? data.item.code.split("-")[0]
                          : data.item.game.name
                      }}
                    </span>
                  </template>
                  <template #cell(details)="data">
                    <div>
                      <vs-button success @click="openDetails(data.item)">{{
                        $t("helpers.look")
                      }}</vs-button>
                    </div>
                  </template>
                  <template #cell(status)="data">{{
                    data.item.result === -1 ? "Opened" : "Closed"
                  }}</template>
                  <template #cell(open)="data">{{
                    data.item.open ? "Opened" : "Closed"
                  }}</template>
                  <template #cell(result)="data">
                    <span :class="`text-light p-1 ${mapColorResult(data.item.result)} ${mapIsButton(filterData)}`"
                      @click="openResult(data.item)" v-if="mapResult(filterData, data.item)">
                      {{ mapResult(filterData, data.item) }}
                    </span>
                  </template>
                  <template #cell(roundBets)="data">
                    <vs-button success>
                      <downloadexcel style="width: max-content" :fetch="() => exportDataBets(data.item.identifierNumber)
                        " :fields="json_bets_fields" :name="'Bets.xls'">
                        {{ $t("tableReports.roundBetBtn") }}
                      </downloadexcel>
                    </vs-button>
                  </template>
                  <template #cell(JACKPOT)="data">
                    <JackpotPill :jackpot="data.item.JACKPOT" />
                  </template>
                </b-table>
                <div v-if="!tableData.length" class="text-center">
                  {{ $t("tableReports.noResults") }}
                </div>
              </div>
            </b-overlay>
            <p class="text-center my-2" v-if="searchActive && !tableData.length && !loading">
              {{ $t("tableReports.noResults") }}
            </p>
            <div class="row" v-if="tableData.length">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <button class="btn btn-secondary mx-2 uil-angle-left" @click="beforePage"
                      :disabled="!skip"></button>
                    <button class="btn btn-primary mx-2 uil-angle-right" @click="nextPage"
                      :disabled="tableData.length > limit"></button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header-simple";
import downloadexcel from "vue-json-excel";
import Swal from "sweetalert2";
import appConfig from "@/app.config";
import { breadCrumbData } from "../../../../helpers/breadcrumbs/breadCrumbs";
import Show from "./show.vue";
import SisterRound from "./sisterRound.vue";
import DragonResult from "./results/DragonResult.vue";
import Tooltip from "../../../../components/tooltip.vue";
import DateRange from "../components/DateRange.vue";
import { onlyAdmins } from "../../../../router/guards/roles";
import RoundStatistics from "../components/RoundStatistics.vue";
import { BlackJackResult } from "./components";
import { JackpotPill } from "../../../../components";

/**
 * Users-table component
 */
export default {
  page: {
    title: "Rounds Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    downloadexcel,
    Show,
    SisterRound,
    Tooltip,
    DateRange,
    DragonResult,
    RoundStatistics,
    BlackJackResult,
    JackpotPill
  },
  data() {
    return {
      //
      searchActive: false,
      fetching: false,
      //

      //Paginacion
      picked: this.$t("helpers.onPage"),
      skip: 0,
      limit: 10,
      disabledNextPage: null,
      disabledPreviousPage: null,
      currencies: [],
      fileName: "Rondas.xls",
      json_fields: {
        "#ID": "identifierNumber",
        "Ronda ID": "code",
        Ruleta: "name",
        // jugador: "players",
        "Fecha inicial": "start_date",
        "Fecha de cierre": "end_date",
        Estatus: "status",
        Resultado: "result",
      },
      json_bets_fields: {
        Numero: "number",
        "Apuesta inicial plenos": "plenoAmount",
        "Riesgo Pleno": "plenoRisk",
        "Demas pagos": "noPlenoAmounts",
        "Riesgo total": "totalRisk",
        RTP: "rtp",
        "Riesgo Pleno 50": "plenoRisk_50",
        "Riesgo total 50": "totalRisk_50",
        "RTP 50": "rtp_50",
        "Riesgo Pleno 100": "plenoRisk_100",
        "Riesgo total 100": "totalRisk_100",
        "RTP 100": "rtp_100",
        "Riesgo Pleno 200": "plenoRisk_200",
        "Riesgo total 200": "totalRisk_200",
        "RTP 200": "rtp_200",
        "Riesgo Pleno 300": "plenoRisk_300",
        "Riesgo total 300": "totalRisk_300",
        "RTP 300": "rtp_300",
        "Riesgo Pleno 500": "plenoRisk_500",
        "Riesgo total 500": "totalRisk_500",
        "RTP 500": "rtp_500",
        "Riesgo Pleno 1000": "plenoRisk_1000",
        "Riesgo total 1000": "totalRisk_1000",
        "RTP 1000": "rtp_1000",
      },
      tableData: [],
      totalRegister: 0,
      totalPages: "...",
      title: this.$t("sidebar.reports.subItems.rounds"),
      items: breadCrumbData.rounds,
      loading: false,
      filterData: {},
      dataDetails: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: "",
      filterOn: ["code"],
      clients: [],
      operators: [],
      games: [],
      sortBy: "end_date",
      sortDesc: true,
      roundBets: [],
      fields: [],
      clientFilter: null,
      reds: [
        1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 25, 27, 30, 32, 34, 36,
      ],
      // downloadSelected: "true",
      jackpots: [],
      round: {},
      rounds: [],
      roundIdentifierNumber: 0,
      userData: "",
      userRole: "",
      gameType: null,

      // ROLES
      readAllRoles: onlyAdmins,
      game: {},
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    ...mapGetters({
      mapGames: "games/getGames",
      mapRounds: "reports/getRounds",
      mapClients: "clients/getClients",
      mapOperators: "operators/getOperators",
      mapResult: "reports/showResult",
      mapColorResult: "reports/colorResult",
      mapIsButton: "reports/mapIsButton",
      mapGamesTypes: "reports/getGamesTypes",
      gamesTypes: "reports/gamesTypes",
      mapRoundStatistics: "reports/mapRoundStatistics",
      mapCurrencies: "currencies/getCurrencies",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.getUserRole();
    // this.getCurrencies();
  },
  methods: {
    async openResult({ result, game, type }) {
      if (!result) return;

      const { refs } = await this.openResultAction({
        result,
        gameType: this.filterData.gameType,
      });

      this.game = { result, name: game.name, type };
      this.round = { result };
      this.$refs[refs].modal = true;
    },
    async handleChange(item) {
      this.tableData = [];
      await this.makeFields(item);

      if (this.userRole === "CLIENT") {
        this.getRoulettesClient();
      } else if (this.userRole === "OPERATOR") {
        this.getRoulettesOperator();
      } else {
        await this.fetchGames({ gameType: item });
        this.games = this.mapGames;
      }
    },
    checkAdmins() {
      return this.readAllRoles.includes(this.userRole);
    },
    async makeFields(gameType) {
      const fields = await this.makeFieldsAction({
        userRole: this.userRole,
        gameType,
      });

      this.fields = fields;
    },
    getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.role.name;

      if (this.userRole === "CLIENT") {
        this.filterData.client = this.userData.client;

        this.getOperatorClient();
        this.getRoulettesClient();
      } else if (this.userRole === "OPERATOR") {
        this.filterData.operatorId = this.userData.operator;
        this.getRoulettesOperator();
      } else {
        this.getClients();
        this.getOperator();
      }
    },
    getOperatorClient() {
      const clientId = this.userData.client;
      this.$http
        .get(`/operators?client=${clientId}`)
        .then(({ data }) => {
          if (data.ok) {
            this.operators = data.operators;
          }
        })
        .catch((err) => {
          console.log("Error al obtener operadores del cliente", err);
        });
    },
    getRoulettesClient() {
      const client = this.userData.client;
      this.$http
        .get(`clients/${client}/roulettes`)
        .then(({ data }) => {
          if (data.clientRoulettes.length) {
            const formatData = data.clientRoulettes.map((element) => {
              return element.roulette;
            });
            return (this.games = formatData);
          }
          this.games = [];
        })
        .catch((err) => {
          console.log("Err", err);
        });
    },
    getRoulettesOperator() {
      const operatorId = this.userData.operator;
      this.$http
        .get(`operators/${operatorId}/roulettes`)
        .then(({ data }) => {
          const formattedData = data.operatorRoullettes.map((element) => {
            return element.roulette;
          });
          this.games = formattedData;
        })
        .catch((err) => {
          console.log("Error al obtener ruletas del operador", err);
        });
    },
    async lookSisterRound(round) {
      this.round = round;
      this.roundIdentifierNumber = round.identifierNumber;
      try {
        const { data } = await this.$http.get("round/sisters", {
          params: {
            identifierNumber: round.identifierNumber,
          },
        });

        this.rounds = data.rounds;
      } catch (error) {
        console.log("Error obteniendo rondas hermanas", error);
      }

      this.$refs.sisterRound.modal = true;
    },
    async lookJackpots(round) {
      this.jackpots = [];
      this.jackpots = await this.getJackpotByGameType({
        type: this.filterData.gameType,
        round,
      });
      this.$refs.showJacks.modal = true;
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "visible";
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "hidden";
      }
    },
    async getClients() {
      try {
        await this.fetchClients();
        this.clients = this.mapClients;
      } catch (error) {
        console.log(error);
      }
    },
    async getOperator() {
      try {
        await this.fetchOperators({ operators: {} });
        this.operators = this.mapOperators;
      } catch (error) {
        console.log(error);
      }
    },
    async filtrar() {
      await this.makeFields(this.filterData.gameType);
      await this.fetchGames({ gameType: this.filterData.gameType });

      this.searchActive = true;
      this.loading = true;

      try {
        await this.fetchRounds({
          gameType: this.filterData.gameType,
          filterData: this.filterData,
          limit: this.limit,
          page: this.currentPage,
        });

        this.tableData = this.mapRounds;

        this.loading = false;
      } catch (error) {
        console.log(error, "Error al filtar rounds");
        this.loading = false;
      }

      this.loading = false;
    },
    cancelFilter() {
      this.searchActive = false;

      this.filterData = {};
      this.getUserRole();
    },
    async getRoulettes() {
      try {
        const { data } = await this.$http.get("/roulettes");
        this.games = data.roulettes;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    nextPage() {
      this.skip += this.limit;
      this.currentPage++;
      this.filtrar();
    },
    beforePage() {
      this.skip -= this.limit;
      this.currentPage--;
      this.filtrar();
    },
    daysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    async handleRadio(value) {
      this.picked = value;
      switch (this.picked) {
        case this.$t("helpers.onPage"):
          break;
        case this.$t("helpers.today"): {
          const initialDate = new Date().toJSON().split("T")[0];
          this.filterData = {
            ...this.filterData,
            start_date: initialDate,
            end_date: initialDate,
          };
          break;
        }
        case this.$t("helpers.month"): {
          let from = 1;
          let to = this.daysInMonth(
            new Date().getMonth(),
            new Date().getFullYear()
          );
          const initialDate = new Date().toJSON().split("T")[0].split("-");
          let start_date = [initialDate[0], initialDate[1], from].join("-");
          let end_date = [initialDate[0], initialDate[1], to].join("-");
          this.filterData = { ...this.filterData, start_date, end_date };
          break;
        }
        default:
          break;
      }
      return true;
    },
    async dataToXls() {
      const { data } = await this.$http.get("/round", {
        params: {
          ...this.filterData,
          limit: 1000,
          page: 1,
          // pagination: this.downloadSelected,
        },
      });
      if (data.ok) {
        const formattedData = [];

        data.rounds.forEach((element) => {
          formattedData.push({
            identifierNumber: element.identifierNumber,
            name: element.code.split("-")[0],
            code: element.code,
            start_date: new Date(element.start_date).toUTCString(),
            end_date: new Date(element.end_date).toUTCString(),
            status: element.result === -1 ? "Abierta" : "Cerrada",
            result: element.result === -1 ? "" : element.result,
          });
        });

        this.fetching = false;

        return formattedData;
      } else {
        Swal.fire({
          position: "top-end",
          icon: "danger",
          title: "Advertencia, no se encuentran datos asociados",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      this.fetching = false;
    },
    async exportData() {
      if (!this.filterData.start_date || !this.filterData.start_date)
        return Swal.fire(
          "Es necesario seleccionar un rango de fecha",
          "",
          "info"
        );

      this.fetching = true;

      return await this.dataToXls();
    },
    async exportDataBets(identifierNumber) {
      const { data } = await this.$http.get(
        `/round/round-bets?identifierNumber=${identifierNumber}`
      );
      if (data.ok) {
        return data.formattedData;
      } else {
        Swal.fire({
          position: "top-end",
          icon: "danger",
          title: "Advertencia, no se encuentran datos asociados",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    setTransactionsRound(item) {
      const roundCode = item.code;
      this.$router.push({
        path: "/reports/transations",
        query: {
          roundCode,
          gameType: this.filterData.gameType,
        },
      });
    },
    async getCurrencies() {
      try {
        await this.fetchCurrencies({
          role: this.userData.role.name,
          user: this.userData,
        });
        this.currencies = this.mapCurrencies;
      } catch (err) {
        console.log(err);
      }
    },
    change() {
      this.filtrar();
    },
    async roundStatistics() {
      try {
        await this.fetchRoundStatistics(this.filterData);
        this.$refs.showRoundStatisticsComponent.date = this.filterData;
        this.$refs.showRoundStatisticsComponent.modal = true;
      } catch (error) {
        console.log("ERROR ROUND STATISTICS", error);
      }
    },
    ...mapActions({
      makeFieldsAction: "reports/makeFields",
      fetchGames: "games/fetchGames",
      fetchRounds: "reports/fetchRounds",
      openResultAction: "reports/openResult",
      fetchClients: "clients/fetchClients",
      fetchOperators: "operators/fetchOperators",
      getJackpotByGameType: "reports/getJackpotByGameType",
      fetchRoundStatistics: "reports/fetchRoundStatistics",
      fetchCurrencies: "currencies/fetchCurrencies",
    }),
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.filtrar();
    },
  },
  middleware: "authentication",
};
</script>

<style>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  left: 0;
  top: -12px;
  visibility: hidden;
  width: max-content;
}

.space-nowrap {
  white-space: nowrap !important;
}

.black {
  background: #000;
}

.red {
  background: #f10;
}

.green {
  background: #00a429;
}
</style>
