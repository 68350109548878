<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      Mesa: {{ dragonTiger.name || "NO GAME NAME" }}
    </template>
    <div v-if="Object.entries(dragonTiger).length && dragonTiger.type === 'DRAGON-TIGER'">
      <div>
        <div class="d-flex justify-content-between mx-3">
          <div>
            <h2 class="text-center">Dragon</h2>
            <div class="card-accepted-container" :style="`color:${dragonTiger.result.card1.color}`">
              <div class="card-accepted">
                <span class="card-left-number">{{ dragonTiger.result.card1.name }}</span>
                <span class="card-type">{{ cardTypes[dragonTiger.result.card1.type] }}</span>
                <span class="card-right-number">{{ dragonTiger.result.card1.name }}</span>
              </div>
            </div>
          </div>
          <div>
            <h2 class="text-center">Tiger</h2>
            <div class="card-accepted-container" :style="`color:${dragonTiger.result.card2.color}`">
              <div class="card-accepted">
                <span class="card-left-number">{{ dragonTiger.result.card2.name }}</span>
                <span class="card-type">{{ cardTypes[dragonTiger.result.card2.type] }}</span>
                <span class="card-right-number">{{ dragonTiger.result.card2.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    dragonTiger: {
      default: () => ({
        name: 'Dragon Tiger',
        result: {
          card1: {
            color: 'black',
            name: 'A',
            type: 'trebol'
          },
          card2: {
            color: 'black',
            name: 'A',
            type: 'trebol'
          }
        }
      })
    }
  },
  data() {
    return {
      modal: false,
      cardTypes: {
        trebol: '♣',
        pica: '♠',
        corazon: '♥',
        diamante: '♦'
      },
    }
  }
}
</script>

<style scoped>
.card-accepted-container {
  display: grid;
  justify-content: center;
}

.card-accepted {
  background-color: rgb(231, 231, 231);
  box-shadow: 0 0 20px rgb(100, 100, 100);
  display: grid;
  border-radius: 5px;
  height: 250px;
  width: 150px;
  /* padding: 10px; */
  grid-template-columns: 30% 40% 30%;
  font-weight: bolder;
  font-size: 2rem;
  grid-template-rows: repeat(3, 33.3%);
}

.card-type {
  grid-row: 2/3;
  grid-column: 2/3;
  justify-self: center;
  font-size: 4rem;
  align-self: center;
}

.card-right-number {
  grid-row: 3/4;
  grid-column: 3/4;
  justify-self: end;
  align-self: end;
}
</style>