<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title> Jackpots Round </template>
    <div class="row mt-4">
      <div v-if="!jackpots.length" class="mb-4">
        No hay Jackpots para esta ronda
      </div>
      <div v-if="jackpots.length" class="row">
        <JackpotPill :jackpot="jackpots" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { JackpotPill } from '../../../../components';

export default {
  components: { JackpotPill },
  props: ["jackpots"],
  data() {
    return {
      modal: false,
      fields: [
        {
          key: "betType",
          label: this.$t('betDetail.type'),
          class: "text-center",
        },
        {
          key: "number",
          label: this.$t('betDetail.number'),
        },
        {
          key: "amount",
          label: this.$t('betDetail.amount'),
          class: "text-center",
        },
        {
          key: "earnings",
          label: this.$t('betDetail.win'),
          class: "text-center",
        },
      ],
      betFields: [
        {
          key: "betType",
          label: this.$t('betDetail.type'),
        },
        {
          key: "number",
          label: this.$t('betDetail.number'),
        },
        {
          key: "amount",
          label: this.$t('betDetail.amount'),
          class: "text-center",
        },
      ],
      dummie: [{ number: 1, amount: 100, type: "pleno" }],
    };
  },
  methods: {
    closeModa() {
      this.modal = false;
      this.items = [];
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "visible";
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "hidden";
      }
    },
  },
};
</script>
